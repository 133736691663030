import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Terms from '../terms/style'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
export default function GdprSection(){
return(
<Terms>
  <Container>
    <Row className="justify-content-center">
      <Col className="col-xl-7 col-lg-8 col-md-11 text-center">
        <Terms.Box>
          <Terms.Title as="h3">General Data Protection Regulation (GDPR)</Terms.Title>
        </Terms.Box>
      </Col>
    </Row>
    <Row className="justify-content-center">
      <Col className="col-xxl-8 col-xl-9 col-lg-10">
        <Terms.Content>
          
          <Terms.ContentBox>
          <Terms.TitleSmall as="h3">What is GDPR?</Terms.TitleSmall>
            <Terms.Text>The intent of the General Data Protection Regulation is to protect the personal data and privacy of its citizens for transactions occurring within EU member states. The GDPR is a way to harmonize data privacy laws across Europe so people can feel safe about how their information gets used when they shop, visit, access or subscribe to any platform. The European Union (EU) General Data Protection Regulation (GDPR) effective on 25 May 2018 replaces the previous EU Data Protection Directive 95/46/EC. GDPR also addresses the export of personal data outside of the EU. Any business that processes personal information about EU citizens within the EU must ensure that they comply with GDPR. Under GDPR, processing personal information means any operation performed on personal data, such as collecting, recording, erasing, usage, transmitting, and disseminating.</Terms.Text>
          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">What do businesses need to know about GDPR?</Terms.TitleSmall>
            <Terms.Text>The General Data Protection Regulation applies to any business with operations in Europe. And depending on what they do and who their customers or clients are - even if that company has no presence there at all - they're still responsible for compliance! To be GDPR-compliant, businesses need to understand what personal data they are processing and make sure appropriate technical measures are in place for its security.</Terms.Text>
          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">Your rights regarding your personal information</Terms.TitleSmall>
            <Terms.Text>You have certain data protection rights. We-Connect aims to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data. If you wish to be informed what Personal Data we hold about you and if you want it to be removed from our systems, please contact us.</Terms.Text>
            <Terms.Text>In certain circumstances, you have the following data protection rights:</Terms.Text>

            <Terms.SubTitle as="h3">a) Right of access/portability</Terms.SubTitle>
            <Terms.Text>The data subject shall have the right to obtain from the controller confirmation whether personal data concerning him or her are being processed, and, where that is the case, access to the personal data […] The controller shall provide a copy of the personal data undergoing processing.</Terms.Text>
            <Terms.Text>As a customer, you have access to your personal information through our platform.</Terms.Text>

            <Terms.SubTitle as="h3">b) Right to rectification</Terms.SubTitle>
            <Terms.Text>The data subject shall have the right to obtain from the controller without undue delay the rectification of inaccurate personal data concerning him or her. Taking into account the purposes of the processing, the data subject shall have the right to have incomplete personal data completed, including by means of providing a supplementary statement.</Terms.Text>
            <Terms.Text>As a customer, you can change your personal data right from our platform.</Terms.Text>

            <Terms.SubTitle as="h3">c) Right to be forgotten & Right to object</Terms.SubTitle>
            <Terms.Text>The data subject shall have the right to obtain from the controller the erasure of personal data concerning him or her without undue delay and the controller shall have the obligation to erase personal data without undue delay. The data subject shall have the right to obtain from the controller restriction of processing. As a customer, all your user’s data is erased within 90 days after you cancel your account. As a user, you can request the deletion of your data at any time. After verification of your identity, a written confirmation of the correct deletion will be given within the next 30 days.</Terms.Text>
            
            
          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">Security</Terms.TitleSmall>
            <Terms.Text>To ensure the security of your data, we have implemented rigorous measures to protect you from any potential threats. All connections are logged and verified before being made available on our infrastructure; double authentication is required for this type of verification!</Terms.Text>
          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">Data processing addendum</Terms.TitleSmall>
            <Terms.Text>To ensure that you comply with the new European General Data Protection Regulation (GDPR), it is important for controllers to have a processor who has been signed on as well. We-Connect makes it easy to get started! Just contact our team and we'll provide you with the contract that guarantees your compliance under article 28 GDPR.</Terms.Text>
          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">What do we do with your personal information?</Terms.TitleSmall>
            <Terms.Text>We-Connect collects your personal data fairly and lawfully and in accordance with General Data Protection Regulation and our Privacy Policy. The main purpose of collecting personal data is to provide you with a safe, optimal, efficient, and personalized experience.</Terms.Text>
          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.SubTitle as="h3">We may use your personal data to:</Terms.SubTitle>
            <Terms.Text>• Provide our service: When creating an account, you agree to our Terms which allows us to process your data to give you access to We-Connect. This way, you can have an account on our platform, save and process prospects, outreach to them, invite other team members, etc.</Terms.Text>
            <Terms.Text>• Offer customer support: We provide customer support to help you take full advantage of our service or fix any problem you encounter.</Terms.Text>
            <Terms.Text>• Fight fraud: We actively fight credit card fraud and misuse of our services by detecting risk patterns.</Terms.Text>
            <Terms.Text>• Market new services: Regularly, we launch new services. We’ll let users know about those releases if they have asked us to keep them up to date.</Terms.Text>
            <Terms.Text>• Perform any other function: that we believe in good faith is necessary to protect the security or proper functioning of our Platform or the We-Connect Services.</Terms.Text>

          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">Legitimate Interest</Terms.TitleSmall>
            <Terms.Text>There are several reasons that are deemed to be legitimate bases for processing data.</Terms.Text>
            <Terms.Text>a) The data subject has given consent</Terms.Text>
            <Terms.Text>b) Processing is necessary for the performance of a contract</Terms.Text>
            <Terms.Text>c) Processing is necessary for compliance with a legal obligationt</Terms.Text>
            <Terms.Text>d) Processing is necessary in order to protect the vital interests of the data subject</Terms.Text>
            <Terms.Text>e) Processing is necessary for the performance of a task carried out in the public interest</Terms.Text>
            <Terms.Text>f) Processing is necessary for the purposes of the legitimate interests</Terms.Text>

            <Terms.Text>We-Connect may use the data collected for other purposes, which you will be informed of at the time the data is collected and be asked to accept.</Terms.Text>

          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">Log Retention</Terms.TitleSmall>
            <Terms.Text>Logs are important for monitoring and debugging the service. We keep them around to make sure our customers' experience on this platform is always great! We now destroy logs within 3 months after their collection date so they won't be used anymore except in rare cases like these where it's necessary, which means you can trust us with your personal data.</Terms.Text>

          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">Data Breaches</Terms.TitleSmall>
            <Terms.Text>Should your personal data that we control be lost, stolen or otherwise breached, where there constitutes a high risk to your rights and freedoms, we will contact you without delay. We will give you the contact details of the Data Protection Officer who is dealing with the breach, explain to you the nature of the breach and the steps we are taking to deal with it.</Terms.Text>
          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">Pseudonymisation of Personal Data</Terms.TitleSmall>
            <Terms.Text>The process of systematic pseudonymisation is an important one for many reasons. It allows us to protect the privacy and anonymity third-parties may have in their data while still allowing organizations access at times when they need information on someone else’s behalf. We ensure the privacy of our data subjects by heavily pseudonymising their information. Any attributes that don’t need to remain in original form are trimmed so they cannot be linked back to a specific person or company, ensuring your personal details stay safe with us!</Terms.Text>
          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">Disclaimer</Terms.TitleSmall>
            <Terms.Text>This GDPR Compliance is provided “as is” and without warranty. In no event will We-Connect or its affiliates have any liability whatsoever arising from in connection with this document. You acknowledge and agree that you are solely responsible for complying with any and all laws and regulations in association with your use of We-Connect and/or other We-Connect products and solutions, including without limitation, laws and regulations related to data privacy.</Terms.Text>
          </Terms.ContentBox>
          
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">CONTACT INFORMATION</Terms.TitleSmall>
            <Terms.Text>Questions about this GDPR document should be sent to us at <a href="mailto: dpo@we-connect.io">dpo@we-connect.io</a></Terms.Text>
          </Terms.ContentBox>
        </Terms.Content>
      </Col>
    </Row>
  </Container>
</Terms>

)
}